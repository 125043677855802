var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row lesson-item ml-0 mr-0",
      style: _vm.getRowStyle(_vm.lesson.lessonTypeId),
      on: { click: _vm.goToLesson }
    },
    [
      !_vm.isLesson && _vm.isIcon(_vm.lesson.lessonTypeId)
        ? _c("span", { staticClass: "mt-1" }, [
            _c("img", {
              staticClass: "sa-image",
              attrs: {
                src: _vm.getIcon(_vm.lesson.lessonTypeId),
                alt: "SAImage"
              }
            })
          ])
        : !_vm.isLesson && !_vm.isIcon(_vm.lesson.lessonTypeId)
          ? _c("span", [
              _c("img", {
                staticClass: "sa-image mt-1",
                attrs: {
                  src: _vm.getIcon(_vm.lesson.lessonTypeId),
                  alt: "SAImage"
                }
              })
            ])
          : _c("span", { staticClass: "lesson-number mt-1" }, [
              _vm._v(_vm._s(_vm.lessonNumber))
            ]),
      _c("span", { staticClass: "lesson-name" }, [
        _vm._v(_vm._s(_vm.lesson.name))
      ]),
      _c("span", { staticClass: "circle", class: _vm.lesson.statusLesson }, [
        _c("div", { class: _vm.statusIcon })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }