<template>
  <div
    class="row lesson-item ml-0 mr-0"
    :style="getRowStyle(lesson.lessonTypeId)"
    @click="goToLesson"
  >
    <span class="mt-1" v-if="!isLesson && isIcon(lesson.lessonTypeId)">
      <img :src="getIcon(lesson.lessonTypeId)" alt="SAImage" class="sa-image" />
    </span>

    <span
      v-else-if="!isLesson && !isIcon(lesson.lessonTypeId)"
    >
      <img :src="getIcon(lesson.lessonTypeId)" alt="SAImage" class="sa-image mt-1" />
    </span>

    <span v-else class="lesson-number mt-1">{{ lessonNumber }}</span>
    <span class="lesson-name">{{ lesson.name }}</span>
    <span class="circle" :class="lesson.statusLesson">
      <div :class="statusIcon"></div>
    </span>
  </div>
</template>

<script>
import { Status } from "@/utils/Status.js";
export default {
  name: "LessonItem",
  props: {
    lesson: Object,
    number: Number,
  },
  computed: {
    unit() {
      return this.$store.state.currentUnit;
    },
    statusIcon() {
      if (this.lesson.statusLesson === Status.NotStarted) return "";
      if (this.lesson.statusLesson === Status.InProgress) return "play";
      return this.lesson.statusLesson === Status.Failed ? "cross" : "check";
    },
    isLesson() {
      return (
        this.lesson.lessonTypeId == this.$config.soaConfig.lessonTypes.lesson.id
      );
    },
    isLessonStory() {
      return (
        this.lesson.lessonTypeId == this.$config.soaConfig.lessonTypes.story.id
      );
    },
    isOverview() {
      return (
        this.lesson.lessonTypeId ==
        this.$config.soaConfig.lessonTypes.overview.id
      );
    },
     isSkillLesson(){
        return (this.lesson.lessonTypeId == this.$config.soaConfig.lessonTypes.skill.id);
      },
    lessonNumber() {
      return this.isLesson ? this.lesson.number : "";
    },
    SAImage() {
      return require("@/assets/SA-2.png");
    },
  },
  methods: {
    goToLesson() {
      if (this.$store.state.isPreviewModeActive) return;
      let route = {};
      if(this.isOverview) {
        // route = { name: "Overview", params: { unitId: this.unit.id } };
        route = {name: "Opener", params :{unitId: this.unit.id}};
      } else if(this.isLessonStory) {
        route = { name: "LessonStory", params: { lessonId: this.lesson.id, unitId: this.unit.id } }
      } else if(this.isLesson) {
        route = {
            name: "Lesson",
            params: { lessonId: this.lesson.id, unitId: this.unit.id },
          };
        
      }
      else if(this.isSkillLesson){
          route = {
            name: "Skill-Lesson",
            params:{lessonId: this.lesson.id, unitId: this.unit.id}
          }
      }
      this.$router.push(route);
      this.$store.commit("updateCurrentAssessmentSection", undefined);
    },
    getLessonType(lessonTypeId) {
      return Object.values(this.$config.soaConfig.lessonTypes).find(
        (el) => el.id == lessonTypeId
      );
    },
    getIcon(lessonTypeId) {
      let lessonType = this.getLessonType(lessonTypeId);
      return lessonType.icon;
    },
    getRowStyle(lessonTypeId) {
      let lessonType = this.getLessonType(lessonTypeId);
      return lessonType.rowStyle;
    },
    getSpanStyle(lessonTypeId) {
      let lessonType = this.getLessonType(lessonTypeId);
      return lessonType.spanStyle;
    },
    isIcon(lessonTypeId) {
      let lessonType = this.getLessonType(lessonTypeId);
      return lessonType.showIcon;
    },
  },
};
</script>

<style lang="less" scoped>
// .lessons .lesson-item:first-child {
//   // text-transform: uppercase;
// }

.lesson-item {
  min-height: 48px;
  height: auto;
  color: rgba(0, 0, 0, 0.6);
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0px;
  > span {
    min-height: 24px;
    // align-self: center;
    // padding-top: 14px
  }
}
.isnot-lesson {
  background: #ccc;
  text-transform: uppercase;
}
.lesson-number {
  width: 45px;
  margin-left: 16.86px;
  padding-top: 10px;
}

.lesson-name {
  font-family: 'Roboto', sans-serif;
  max-width: 434px;
  -ms-flex-item-align: center;
  align-self: center;
  white-space: normal;
  padding: 8px;
  height: auto;
}
 // Large devices (large desktops, 991.98px and up)
  @media (min-width: 991.98px) { 
    .lessons-dashboard {
        .lesson-name {
          width: calc(100% - 120px);
        }
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) { 
    .lessons-dashboard {
    .unit-title__text{
      font-size: 28px;
      line-height: 28px;
    } 
    .lesson-name {
      width: calc(100% - 120px);
    }
    }
  }
.edit-button {
  margin-left: 25.3px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.circle {
  margin-left: auto;
  margin-right: 27.75px;
  margin-top: 12px;
}

.lesson-item:hover {
  background: #eaebff;
  cursor: pointer;

  span {
    color: #3a4dfd;
  }
  .circle {
    background: #eaebff;
    color: #eaebff;
    margin-top: 14px;
  }
  span > div {
    position: absolute;
    top: 25%;
    left: 20%;
    color: #fff;
    width: 10px;
    height: 10px;
    border-top: none;
    border-left: none;
    border-radius: none;
    margin: 0;
    border-bottom: solid 3px rgba(0, 0, 0, 0.38);
    border-right: solid 3px rgba(0, 0, 0, 0.38);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
.is-overview {
  background: #5459c1;
  color: #fff;
}
.sa-image {
  width: 40px;
  height: 40px;
  margin-right: 15.86px;
  margin-left: 8px;
}
.rounded {
  width: 40px;
  height: 10px;
  border-radius: 25px;
  margin-right: 5.86px;
  margin-left: 8px;
}
.rounded {
  width: 40px;
  height: 10px;
  border-radius: 25px;
  margin-right: 5.86px;
  margin-left: 8px;
}
</style>
