var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.unit
    ? _c(
        "div",
        {
          staticClass: "lessons-dashboard flex-container",
          style: {
            "background-image": "url(" + _vm.lessonBackground + ")"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "overview-title",
              staticStyle: { position: "relative" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "unit-information flex-container",
                  staticStyle: { position: "absolute", top: "16px" },
                  on: { click: _vm.showTeacherPage }
                },
                [
                  _vm.infoIcon
                    ? _c("img", { attrs: { src: _vm.infoIcon } })
                    : _vm._e()
                ]
              ),
              _vm.unit
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.unit.name,
                          expression: "unit.name"
                        }
                      ],
                      staticClass: "pl-5 unit-title__text"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.unit.name) +
                          _vm._s(
                            _vm.unit.name && _vm.unit.period !== null
                              ? " , "
                              : ""
                          ) +
                          _vm._s(_vm.unit.period) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c("div", { staticClass: "unit-details flex-container" }, [
            _c("img", { staticClass: "unit-image", attrs: { src: _vm.image } }),
            _c("span", { staticClass: "unit-name" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.unit.number) +
                  " " +
                  _vm._s(_vm.unit.name) +
                  " " +
                  _vm._s(_vm.unit.period)
              )
            ]),
            _c(
              "div",
              {
                staticClass: "unit-information flex-container",
                on: { click: _vm.showTeacherPage }
              },
              [
                _vm.infoIcon
                  ? _c("img", { attrs: { src: _vm.infoIcon } })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.$t("unitSkills")))])
              ]
            ),
            _c(
              "div",
              { staticClass: "unit-description" },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("unitDescription")))]),
                _vm.unit.descriptionAudio
                  ? _c("audio-toggle", {
                      attrs: { audio: _vm.unit.descriptionAudio }
                    })
                  : _vm._e(),
                _c("pre", [_vm._v(_vm._s(_vm.unit.description))])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "dashboard-contents" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row ml-0 mr-0" }, [
                _c(
                  "div",
                  { staticClass: "unit-tag-number col-md-2 offset-4" },
                  [
                    _vm._v("\n          Unit\n          "),
                    _c("div", { staticClass: "unit-number" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.unit.number) +
                          "\n          "
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "col-md-6 pl-5 pr-5" }, [
                  _c("div", { staticClass: "lessons-board" }, [
                    _c("div", { staticClass: "board-title" }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("lessons")))])
                    ]),
                    _vm.unit.lessons
                      ? _c(
                          "div",
                          { staticClass: "lessons mb-5" },
                          _vm._l(_vm.unit.lessons, function(lesson, index) {
                            return _c("lesson-item", {
                              key: index,
                              attrs: { number: index, lesson: lesson }
                            })
                          })
                        )
                      : _vm._e()
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }