<template>
  <div
    class="lessons-dashboard flex-container"
    v-if="unit"
    :style="{
      'background-image':  'url(' + lessonBackground + ')'
    }"
  >
    <div
      class="overview-title"
      style="position: relative"
    >
      <div class="unit-information flex-container" style="position:absolute; top:16px" @click="showTeacherPage">
        <img v-if="infoIcon" :src="infoIcon" />
      </div>
      <span v-tooltip="unit.name" v-if="unit" class="pl-5 unit-title__text"
        >{{ unit.name }}{{ unit.name && unit.period !== null ? " , " : ""
        }}{{ unit.period }}
      </span>
    </div>

    <div class="unit-details flex-container">
      <img :src="image" class="unit-image" />
      <span class="unit-name">
        {{ unit.number }} {{ unit.name }} {{ unit.period }}</span
      >
      <div class="unit-information flex-container" @click="showTeacherPage">
        <img v-if="infoIcon" :src="infoIcon" />
        <span>{{ $t("unitSkills") }}</span>
      </div>
      <div class="unit-description">
        <span>{{ $t("unitDescription") }}</span>
        <audio-toggle
          v-if="unit.descriptionAudio"
          :audio="unit.descriptionAudio"
        />
        <pre>{{ unit.description }}</pre>
      </div>
    </div>
    <div class="dashboard-contents">
      <div class="container-fluid">
        <div class="row ml-0 mr-0">
          <div class="unit-tag-number col-md-2 offset-4">
            Unit
            <div class="unit-number">
              {{ unit.number }}
            </div>
          </div>
          <div class="col-md-6 pl-5 pr-5">
            <div class="lessons-board">
              <div class="board-title">
                <span>{{ $t("lessons") }}</span>
              </div>
              <div class="lessons mb-5" v-if="unit.lessons">
                <lesson-item
                  v-for="(lesson, index) of unit.lessons"
                  :key="index"
                  :number="index"
                  :lesson="lesson"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LessonItem from "@/components/LessonItem.vue";
import AudioToggle from "@/common/AudioToggle.vue";

export default {
  name: "LessonsDashboard",
  props: {
    titleBackgroundColor: "#0a1554",
  },
  components: {
    LessonItem,
    AudioToggle,
  },
  methods: {
    showTeacherPage() {
      if (this.$store.state.isPreviewModeActive) return;
      this.$router.push({
        name: "TeacherPageUnit",
        params: {
          teacherPageId: this.unit.skillsId,
        },
        query: {
          title: this.unit.name,
        },
      });
    },
  },
  mounted() {
    this.$store.commit("loadLesson", null);
    this.startTrackingPage({ sectionName: "Lessons Dashboard" });
    this.$store.commit("setAnalyzedManagementSection", "Lessons Dashboard");
  },
  computed: {
    unit() {
      return this.$store.state.currentUnit;
    },
    image() {
      return this.getImageUrl(this.unit.imageName);
    },
    infoIcon() {
      return require("@/assets/icons/ic_info.svg");
    },
    overview() {
      return this.$store.state.overview;
    },
    background() {
      return this.getImageUrl(this.overview.backgroundImageName);
    },
    lessonBackground(){
      if(this.unit.headerBackgroundImageName === null){
        return;
      }
      else{
          return this.getImageUrl(this.unit.headerBackgroundImageName)
      }
      
    }
  },
  created() {
    this.$store.state.overview &&
    this.$store.state.overview.unitId == this.$route.params.unitId
      ? (this.blurbsChecked = this.$store.getters.blurbs.filter(
          (b) => b.clicked == true
        ).length)
      : this.$store.dispatch("loadOverview", this.$route.params.unitId);

    this.$store.commit("loadLesson", null);
  },
  beforeDestroy() {
    this.$store.commit("setAnalyzedManagementSection", "");
    this.stopTrackingPage({ sectionName: "Lessons Dashboard" });
  },
};
</script>

<style lang="less" scoped>
.dashboard-contents {
  gap: 12%;
  display: flex;
  justify-content: end;
}
.unit-number {
  margin-top: -25px;
  display: flex;
  justify-content: flex-end;
  font-size: 136px;
}
.unit-tag-number {
  // top: 24px;
  text-shadow: -4px 4px 2px rgba(0, 0, 0, 0.6);
  width: 128px;
  font-family: Roboto, sans-serif;
  color: white;
  font-size: 48px;
  font-weight: 500;
  text-transform: uppercase;
}
.lessons-dashboard {
  // background: #3a4dfd;
  background-size: cover;
  display: block;
  width: auto;
  // padding-top: calc(104px + 22px);
  margin-top: calc(104px + 22px);
  justify-content: center;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 84.5vh;
  min-height: calc(100vh - 102px);
  // @media (min-width: 1400px) {  
  //     width: 100%;
  //     min-height: 84.6vh;
  // }
  // @media (min-width: 2000px) {  
  //     width: 100%;
  //     min-height: 91.2vh;
  // }
  // Large devices (large desktops, 991.98px and up)
  @media (min-width: 991.98px) { 
      .lesson-name {
        width: calc(100% - 120px);
      }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) { 
    .unit-title__text{
      font-size: 28px;
      line-height: 28px;
    } 
    .lesson-name {
      width: calc(100% - 120px);
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    .unit-title__text{
      font-size: 20px;
      line-height: 22px;
    } 
    .unit-tag-number {
      width: 216px !important;
      max-width: 216px;
    }
  }
}

.unit-details {
  display: none;
  flex-direction: column;
  height: 60vh;
  background-color: white;
  padding: 30px;
  .unit-image {
    height: auto;
    width: 100%;
    max-height: 140px;
    max-width: 300px;
    border-radius: 10px;
  }

  .unit-name {
    max-width: 304px;
    color: #0a1554;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 16px;
    margin-top: 7px;
    overflow: hidden;
    white-space: normal;
    span {
      margin-right: 8px;
    }
  }
}

.unit-information {
  margin-right: 16px;
  // height: 16px;
  color: #3a4dfd;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 20px;
  cursor: pointer;
  // img {
  //   height: 20px;
  //   width: 20px;
  // }
  span {
    align-self: center;
    margin-left: 14px;
    text-transform: none;
  }
}

.unit-description {
  margin-top: 17px;
  width: 308px;
  overflow-y: auto;
  span {
    height: 24px;
    color: #3a4dfd;
    font-family: "Roboto", sans-serif;
    font-size: 19.64px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 24px;
  }

  .audio-toggle {
    margin-left: 10px;
  }

  pre {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 20px;
  }
}

.lessons-board {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 16px;
  max-height: 200vh;
  position: relative;
  box-shadow: 0 4px 20px 0 rgba(10, 21, 84, 0.2);

  .board-title {
    background: #0a1554;
    letter-spacing: 0.25px;
    padding-bottom: 8px;
    padding-top: 8px;
    color: #ffffff;
    font-size: 23.92px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    display: grid;
    text-transform: capitalize;
    span {
      margin: auto;
    }
  }
  .lessons {
    background-color: #ffffff;
    width: inherit;
    height: fit-content;
  }
}
.overview-title {
  text-shadow: -4px 4px 2px rgba(0,0,0,0.6);
  font-family: "Roboto", sans-serif;
  margin-top: -24px;
  padding-left: 24px;
  color: white;
  background: #0a1554;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 41px;
  // width: 1325px;
  min-height: 64px;
  display: flex;
  align-items: center;
  min-width: 100%;
  white-space: normal;
  text-overflow: initial;
  overflow: auto;
  // margin-bottom: 63px;
}
.unit-title__text{
  word-break: break-word;
  white-space: normal;
  padding-top: 8px;
  padding-bottom: 8px;
}


</style>

